<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        keyword-placeholder="姓名、订单编号"
        :tool-list="[ 'keyword']"
        :filter-keys="['payMethod']"
        @on-search="renderTable(1)">
        <template #pre-form>
          <el-form-item label=" " label-width="30">
            <el-radio-group size="small" v-model="queryInfo.tradeType" @change="renderTable(1)">
              <el-radio-button label="partTimeTuitionFee">普通非全日制</el-radio-button>
              <el-radio-button label="partTimeCustomTuitionFee">自定义非全日制</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </template>
        <el-form-item label="时间" prop="startDate" label-width="60">
          <el-row>
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                size="mini"
                placeholder="开始时间"
                v-model="queryInfo.startDate"
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
            <el-col class="line" style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                size="mini"
                placeholder="结束时间"
                v-model="queryInfo.endDate"
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="订单状态" prop="ordrStcd">
          <el-select size="small" v-model="queryInfo.ordrStcd" clearable>
            <el-option v-for="{ name, type } in orderStatusList" :label="name" :value="type" :key="type" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-tag class="successNum">成功支付人数：{{ countForm.successNum }}</el-tag>
        <el-tag type="success">成功支付金额：{{ countForm.successAmountSum }}元</el-tag>
        <el-button type="success" size="small" @click="addData.isOpen = true">导出订单</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="orderNo" label="客户方主订单号" width="250" />
      <el-table-column v-if="payMethod === '移动支付'" prop="primOrdrNo" label="订单编号" width="300" />
      <el-table-column label="订单状态" width="120" align="center">
        <template v-slot="{row}">
          {{ orderStatusList[Number(row.ordrStcd || 1) - 1].name }}
        </template>
      </el-table-column>
      <el-table-column v-if="payMethod !== '移动支付:中行'" prop="totalPrice" label="订单实付金额" width="130" />
      <el-table-column v-if="payMethod === '移动支付:中行'" prop="amount" label="订单实付金额" width="150" />
      <el-table-column v-if="payMethod !== '移动支付:中行'" prop="ordrGenTm" label="订单日期" width="200" />
      <el-table-column v-if="payMethod === '移动支付:中行'" prop="createTime" label="创建时间" width="300" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column prop="idNumber" label="身份证" min-width="200" />
    </el-table>
    <selectDate :addData="addData" @confirm="confirmHandle" />
  </table-view>
</template>

<script>
import {
  getPayOrderListApi,
  getTuitionOrderOrderListApi,
  getBocOrderOrderListApi
} from '@/api/finance-manage/studentOrder'
import selectDate from './component/selectDate.vue'
import tableView from '@/vue/mixins/table-view'
import {
  orderStatusList,
  payTypeList
} from '@/views/pages/stuInfoPartTime/pay-order/component/options'

export default {
  components: {
    selectDate
  },
  mixins: [tableView],
  data() {
    return {
      payMethod: '移动支付',
      addData: {
        id: '',
        isOpen: false,
        title: '导出订单'
      },
      queryInfo: {
        startDate: null,
        endDate: null,
        ordrStcd: null,
        tradeType: 'partTimeTuitionFee', // 重置时不能清空
        payMethod: '移动支付'
      },
      countForm: {
        successNum: 0,
        failNum: 0,
        successAmountSum: 0
      },
      orderStatusList,
      payTypeList
    }
  },
  async mounted() {
    await Promise.all([
      this.renderTable(1)
    ])
  },
  methods: {
    getTableData(apiMethod, pageNum) {
      if (pageNum) this.queryInfo.pageNum = pageNum
      this.loading = true
      apiMethod(this.queryInfo).then(res => {
        ({
          list: this.tableData,
          total: this.total,
          successNum: this.countForm.successNum,
          successAmountSum: this.countForm.successAmountSum,
          payMethod: this.payMethod
        } = res.data)
      }).catch((e) => {
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取订单列表
    renderTable(pageNum) {
      switch (this.queryInfo.payMethod) {
        case '移动支付':
          this.getTableData(getPayOrderListApi, pageNum)
          break
        case '移动支付:中行':
          this.getTableData(getBocOrderOrderListApi, pageNum)
          break
        default:
          this.getTableData(getTuitionOrderOrderListApi, pageNum)
      }
    },
    // 导出订单
    confirmHandle(data) {
      data.tradeType = 'partTimeTuitionFee'
      this.$http.exportExcelHttp.exportPayOrder(data).then(res => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.successNum {
  margin-right: 20px;
}
</style>
